import React, { useState, useEffect, useRef } from 'react';
import { useEffectAsync } from './../reactHelper';
import ReactDOM from 'react-dom';
import { Container, makeStyles, Paper, Slider, Tooltip, FormControl, InputLabel, Select, MenuItem, Button, TextField, ButtonGroup } from '@material-ui/core';
import MainToolbar from '../MainToolbar';
import Map, { map } from './Map';
import mapboxgl from '!mapbox-gl';
import t from '../common/localization';
import GeofenceMap from './GeofenceMap';
import TrafficSignsMap from './TrafficSignsMap';
import moment from 'moment';
import { useSelector, Provider } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import AnimatedPopup from 'mapbox-gl-animated-popup';
import { useHistory } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';

import { AreaChart, LineChart, ComposedChart, Area, Line, Brush, CartesianGrid, XAxis, YAxis, ResponsiveContainer, ReferenceLine, ReferenceArea, Tooltip as RechartTooltip} from 'recharts';

import Autocomplete from '@material-ui/lab/Autocomplete';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTachometerAlt } from '@fortawesome/free-solid-svg-icons'
import { faCarBattery } from '@fortawesome/free-solid-svg-icons'
import { faGasPump } from '@fortawesome/free-solid-svg-icons'
import { faChargingStation } from '@fortawesome/free-solid-svg-icons'
import { faThermometerFull } from '@fortawesome/free-solid-svg-icons'
import { faTint } from '@fortawesome/free-solid-svg-icons'
import { faClock } from '@fortawesome/free-solid-svg-icons'
import { faRoad } from '@fortawesome/free-solid-svg-icons'
import { faWalking } from '@fortawesome/free-solid-svg-icons'
import { faBackward } from '@fortawesome/free-solid-svg-icons'
import { faForward } from '@fortawesome/free-solid-svg-icons'
import { faPlay } from '@fortawesome/free-solid-svg-icons'
import { faPause } from '@fortawesome/free-solid-svg-icons'
import { faCaretUp } from '@fortawesome/free-solid-svg-icons'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { faFlag } from '@fortawesome/free-solid-svg-icons'
import { faFlagCheckered } from '@fortawesome/free-solid-svg-icons'
import { faStopwatch } from '@fortawesome/free-solid-svg-icons'

import IconButton from '@material-ui/core/IconButton';
import { faReply } from '@fortawesome/free-solid-svg-icons'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'

import { faChartArea } from '@fortawesome/free-solid-svg-icons'
import { faSlash } from '@fortawesome/free-solid-svg-icons'
import { faPrint } from '@fortawesome/free-solid-svg-icons'
import { faGlobeAfrica } from '@fortawesome/free-solid-svg-icons'
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

import ErrorIcon from '@material-ui/icons/Error';

import ReplayPathMap from './ReplayPathMap';

import { getDeviceName, getDeviceIconPath, getClosestGeofence, idleSpeed } from './mapUtil';

import { renderToString } from 'react-dom/server'
import SearchIcon from "@material-ui/icons/Search";
import { counter } from '@fortawesome/fontawesome-svg-core';
import { faUser } from '@fortawesome/free-solid-svg-icons'

import store from '../store';
import DeviceStatusHistoryView from './DeviceStatusHistoryView';

import ManageDirections from './ManageDirections';

import { useWindowDimensions } from '../common/WindowDimensions';
import { getHMS } from '../common/formatter';

import { eventDescription } from './ReplayPathMap';

require('moment-timezone');

import SvgIcon from '@material-ui/core/SvgIcon';

const EngineIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        fill="currentColor"
        d="M43.58,92.2L31.9,80.53h-8.04c-2.81,0-5.11-2.3-5.11-5.11v-8.7h-4.87V76.9c0,2.17-1.78,3.95-3.95,3.95H3.95 C1.78,80.85,0,79.07,0,76.9V42.4c0-2.17,1.78-3.95,3.95-3.95h5.98c2.17,0,3.95,1.78,3.95,3.95v10.18h4.87v-9.36 c0-2.81,2.3-5.11,5.11-5.11h8.54l12.07-12.83c1.4-1.22,3.26-1.65,5.43-1.56h49.73c1.72,0.19,3.03,0.85,3.83,2.09 c0.8,1.22,0.67,1.91,0.67,3.28v23.49H109V42.4c0-2.17,1.78-3.95,3.95-3.95h5.98c2.17,0,3.95,1.78,3.95,3.95v34.5 c0,2.17-1.78,3.95-3.95,3.95h-5.98c-2.17,0-3.95-1.78-3.95-3.95V66.72h-4.87v0.92c0,2.73,0.08,4.38-1.66,6.64 c-0.33,0.43-0.7,0.84-1.11,1.22L83.53,92.96c-0.89,0.99-2.24,1.53-4.02,1.63h-30.4C46.84,94.49,44.99,93.71,43.58,92.2L43.58,92.2z M63.71,61.78l-12.64-1.19l10.48-22.96h14.33l-8.13,13.17l14.62,1.62L55.53,84.64L63.71,61.78L63.71,61.78z M51.98,0h34.5 c2.17,0,3.95,1.78,3.95,3.95v5.98c0,2.17-1.78,3.95-3.95,3.95H76.3v5.03H62.16v-5.03H51.98c-2.17,0-3.95-1.78-3.95-3.95V3.95 C48.03,1.78,49.81,0,51.98,0L51.98,0z"
        />
    </SvgIcon>
  );
}

const colorThumb = '#eb8f3a';
const colorSliderLeft = '#eb8f3a'; //"#00BB00"
const colorSliderRight = '#eb8f3a'; //"#FF0000"

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  controlPanel: {
    position: 'absolute',
    bottom: '30px',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  controlContent: {
    padding: '20px',
    marginBottom: theme.spacing(2),
  },
  configForm: {
    display: 'flex',
    flexDirection: 'column',
  },
  FormControl: {
  },
  thumb: {
    background: colorThumb,
    width: '20px',
    height: '20px',
    marginLeft: '-10px',
    marginTop: '-10px'
  },
  rail: {
    background: "#eb8f3a"
  },
  track: {
    background: "#eb8f3a"
  },
  mapContainer: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: '0px'
  },
  fullWidthInPortrait: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  positionLeftInLandscape: {
    [theme.breakpoints.up('sm')]: {
      position: 'absolute',
      left: '10px',
    },
  },
  showInLandscape: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

const ReplayPage = () => {
  ManageDirections({'replayPage':true});
  global.showTrace = false;
  global.selectedMenu = "/replay"

  if (map.getLayer('trace')) map.removeLayer('trace');
  if (map.getSource('trace')) map.removeSource('trace');

  const chartColorSpeed = 'rgb(35, 148, 204)';
  const chartColorPower = 'rgb(35, 200, 35)';
  const chartColorFuel = 'rgb(200, 35, 35)';
  const chartColorRpm = '#eb8f3a';
  const chartColorTemperature = 'rgb(200, 35, 200)';

  const chartColorDriving = 'rgb(0, 240, 00)';
  const chartColorIdle = 'rgb(240, 240, 00)';
  const chartColorStopped = 'rgb(240, 0, 0)';

  const history = useHistory();
  const classes = useStyles();

  const [positions, setPositions] = useState([]);
  const [index, setIndex] = useState([0, 0]);
  const [trips, setTrips] = useState([]);
  const [tripTotals, setTripTotals] = useState({});
  const [devices, setDevices] = useState([]);
  const [deviceId, setDeviceId] = useState(global.replay.device.id || 0);
  const [device, setDevice] = useState();
  const [period, setPeriod] = useState(global.replay.period);
  const [from, setFrom] = useState(global.replay.from || moment().startOf('day'));
  const [to, setTo] = useState(global.replay.to || moment().endOf('day'));

  const [minTime, setMinTime] = useState(moment());
  const [maxTime, setMaxTime] = useState(moment());

  const [chartType, setChartType] = React.useState(() => ['speed']);
  const [resultStatus, setResultStatus] = React.useState(false);
  const [statusDialog, setStatusDialog] = useState({title: '', message: ''});
  const [loading, setLoading] = useState(false);

  const [hasFuel, setHasFuel] = useState(false);
  const [maxSpeedFound, setMaxSpeedFound] = useState(0);
  const [maxPowerFound, setMaxPowerFound] = useState(0);
  const [maxRpmFound, setMaxRpmFound] = useState(0);
  const [hasTemperature, setHasTemperature] = useState(false);
  const [minTemperature, setMinTemperature] = useState(50);
  const [maxTemperature, setMaxTemperature] = useState(-50);

  const [showChart, setShowChart] = useState(true);
  const [animation, setAnimation] = useState(100);
  const [showIcon, setShowIcon] = useState(true);

  const [autocomplete, setAutocomplete] = useState(global.replay.device);

  const { pageHeight, pageWidth } = useWindowDimensions();
  const [mainContainerHeight, setMainContainerHeight] = useState(0)
  const main_container = useRef(null)

  const temporaryAccess = useSelector(state => state.session.user && state.session.user.email.startsWith('temp_') );
  const temporaryAccessHasHistoryAccess = useSelector(state => state.session.user
    && state.session.user.email.startsWith('temp_')
    && state.session.user.attributes
    && state.session.user.attributes.allowHistory
    );

  useEffectAsync(async () => {
    if (global.devices.length>0) {
      console.log("using devices from GLOBAL")
      setDevices(global.devices);
    } else{
      console.log("using devices from API")
      const response = await fetch('/api/devices');
      if (response.ok) {
        setDevices(await response.json());
      }        
    }
  }, []);

  useEffect(() => {
    if (devices.length>0 && history.location.data) {
      devices.forEach(device => {
        if (device.id == history.location.data.selectedId) {
          setAutocomplete(device);
          global.replay.device = device
          getPositionsFromApi(
            device.id,
            moment().startOf('day').toISOString(),
            moment().endOf('day').toISOString()
          );          
        }
      });
    }
  }, [devices]);

  useEffect(() => {
    if (temporaryAccess && !temporaryAccessHasHistoryAccess) history.push('/');
  }, [temporaryAccess, temporaryAccessHasHistoryAccess])

  useEffect(() => {
    setMainContainerHeight(main_container.current.clientHeight)
  })

  var popup = new AnimatedPopup({
    //https://easings.net/
    //https://docs.mapbox.com/mapbox-gl-js/api/markers/#popup
    closeButton: true,
    closeOnClick: true,
    offset: 15,      
    openingAnimation: {
        duration: 200,
        easing: 'easeInOutSine'
    },
    closingAnimation: {
        duration: 200,
        easing: 'easeInOutSine'
    }
  });

  useEffect(() => {
    map.on('zoom', () => {
      if (map.getLayer('route-start')) map.setLayoutProperty('route-start', 'icon-size', getIconSize());
      if (map.getLayer('route-heading')) map.setLayoutProperty('route-heading', 'icon-size', getIconSize());
      if (map.getLayer('route-event')) map.setLayoutProperty('route-event', 'icon-size', getIconSize());
      if (map.getLayer('route-end')) map.setLayoutProperty('route-end', 'icon-size', getIconSize());
    });

    map.on('mouseenter', 'route-start', showPointer);
    map.on('mouseenter', 'route-heading', showPointer);
    map.on('mouseenter', 'route-event', showPointer);
    map.on('mouseenter', 'route-end', showPointer);

    map.on('mouseleave', 'route-start', hidePointer);
    map.on('mouseleave', 'route-heading', hidePointer);
    map.on('mouseleave', 'route-event', hidePointer);
    map.on('mouseleave', 'route-end', hidePointer);

    map.on('click', 'route-start', showPopup);
    map.on('click', 'route-heading', showPopup);
    map.on('click', 'route-event', showPopup);
    map.on('click', 'route-end', showPopup);

    return () => {
      map.off('mouseenter', 'route-start', showPointer);
      map.off('mouseenter', 'route-heading', showPointer);
      map.off('mouseenter', 'route-event', showPointer);
      map.off('mouseenter', 'route-end', showPointer);
  
      map.off('mouseleave', 'route-start', hidePointer);
      map.off('mouseleave', 'route-heading', hidePointer);
      map.off('mouseleave', 'route-event', hidePointer);
      map.off('mouseleave', 'route-end', hidePointer);
  
      map.off('click', 'route-start', showPopup);
      map.off('click', 'route-heading', showPopup);
      map.off('click', 'route-event', showPopup);
      map.off('click', 'route-end', showPopup);

      document.querySelectorAll('.mapboxgl-popup-wrapper').forEach(item => {
        item.remove();
      })      

    };


  }, []);

  global.map_registerGeofenceEvent = true;

  function getIconSize() {
    var zoomLevelFactor = map.getZoom()/20 + 0.3;
    return keepInRange(zoomLevelFactor, 0, 1);
  }

  function keepInRange(value, min, max)
  {
    if (value>=max) return max;
    if (value<=min) return min;
    return value;
  }

  const handleSubmit = () => {
    setAnimationSpeed(0);
    let selectedFrom;
    let selectedTo;
    switch (period) {
      case 'today':
        selectedFrom = moment().startOf('day');
        selectedTo = moment().endOf('day');
        break;
      case 'yesterday':
        selectedFrom = moment().subtract(1, 'day').startOf('day');
        selectedTo = moment().subtract(1, 'day').endOf('day');
        break;
      case 'dayBeforeYesterday':
        selectedFrom = moment().subtract(2, 'day').startOf('day');
        selectedTo = moment().subtract(2, 'day').endOf('day');
        break;
      case 'thisWeek':
        selectedFrom = moment().startOf('week');
        selectedTo = moment().endOf('week');
        break;
      case 'previousWeek':
        selectedFrom = moment().subtract(1, 'week').startOf('week');
        selectedTo = moment().subtract(1, 'week').endOf('week');
        break;
      default:
        selectedFrom = from;
        selectedTo = to;
        break;
    }

    getPositionsFromApi(
      deviceId,
      selectedFrom.format('YYYY-MM-DD HH:mm:ss'),
      selectedTo.format('YYYY-MM-DD HH:mm:ss')
    );
  }

  const getPositionsFromApi = async (deviceId, from, to) => {
    setLoading(true);

    setDeviceId(deviceId);

    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ devices: [deviceId], from, to, mode: '' })
    };

    const response = await fetch('/report/report_routechartdata.php', options);

    if (response.ok) {
      setLoading(false);
      updateChart(deviceId, await response.json());
    }
  };

  function updateChart(deviceId, data) {  

    var min = 0;
    var max = 0;
    var fixTimeOld = 0

    var positions = data['positions'];
    var trips = data['trips'];

    if (positions.length == 0) {
      showStatusDialog('Oops!', t('noDataForTheSelectedPeriod'))
      setResultStatus(true);
    }
    console.log('Received positions: ' + positions.length)
    var foundFuel = false;
    var maxRpm = 0;
    var maxSpeed = 0;
    var maxPower = 0;
    var foundTemperature = false;
    var minTemperature = 50;
    var maxTemperature = -50;
    var ignitionDrivingTail = false;
    var ignitionIdleTail = false;

    for (var i = 0; i < positions.length; i++) {
      var fixTime = moment.parseZone(positions[i].fixTime).valueOf()
      positions[i].fixTime = fixTime
      positions[i].deviceId = deviceId;
      positions[i].index = i;

      positions[i].ignitionOff = 1
      if (positions[i] && positions[i].attributes && positions[i].attributes.hasOwnProperty('ignition')) {
        var ignition = positions[i].attributes.ignition;
        var speed = positions[i].speed;
        if (speed>maxSpeed) maxSpeed = speed;

        if (ignitionDrivingTail) {
          positions[i].ignitionDriving = 1
          ignitionDrivingTail = false
        }

        if (ignition && speed > idleSpeed) {
          positions[i].ignitionDriving = 1
          ignitionDrivingTail = true
        }

        if (ignitionIdleTail) {
          positions[i].ignitionIdle = 1
          ignitionIdleTail = false
        }

        if (ignition && speed <= idleSpeed) {
          positions[i].ignitionIdle = 1
          ignitionIdleTail = true
        }
      }

      if (
        !foundFuel
        && positions[i].attributes
        && positions[i].attributes.hasOwnProperty('fuel')
        && positions[i].attributes.fuel
        ) {
          foundFuel = true;
      }
      if (
        positions[i].attributes
        && positions[i].attributes.hasOwnProperty('rpm')
        && positions[i].attributes.rpm>maxRpm
        ) {
          maxRpm = positions[i].attributes.rpm;
      }
      if (
        positions[i].attributes
        && positions[i].attributes.hasOwnProperty('rpm')
        && positions[i].attributes.power>maxPower
        ) {
          maxPower = positions[i].attributes.power;
      }
      if (
        positions[i].attributes
        && positions[i].attributes.hasOwnProperty('temp1')
        && positions[i].attributes.temp1
        ) {
          foundTemperature = true;
          if (positions[i].attributes.temp1 < minTemperature) {
            minTemperature = positions[i].attributes.temp1
          }
          if (positions[i].attributes.temp1 > maxTemperature) {
            maxTemperature = positions[i].attributes.temp1
          }
      }

      if (positions[i].attributes && positions[i].attributes.privacy == 1) {
        positions[i] = { fixTime };
      }

      const step = 1000

      if (i>0 && fixTime - fixTimeOld > step) {
        var newPositionCounter = 0;
        for (var j = fixTimeOld + step; j < fixTime; j = j + step) {
          positions.splice(i+newPositionCounter, 0, { fixTime: j });
          newPositionCounter++;
        }
      }

      min = (i==0 ? fixTime : min);
      max = fixTime;

      fixTimeOld = fixTime
    }

    setMinTime(min)
    setMaxTime(max)
    var realPositions = filterRealPositions(positions)

    setHasFuel(foundFuel)
    setMaxSpeedFound((Math.floor(maxSpeed/10)+1)*10)
    setMaxPowerFound((Math.floor(maxPower/10)+1)*10)
    setMaxRpmFound(maxRpm > 0 ? (Math.floor(maxRpm/100)+1)*100 : 0)

    setHasTemperature(foundTemperature)

    if (minTemperature > 0) {
      minTemperature = 0
    } else {
      minTemperature = minTemperature - 3
    }

    if (maxTemperature < 0) {
      maxTemperature = 0
    } else {
      maxTemperature = maxTemperature + 3
    }

    setMinTemperature(minTemperature)
    setMaxTemperature(maxTemperature)

    setDevice(getDevice(deviceId))

    setIndex([0, positions.length - 1]);

    setPositions(positions);

    var tripCounter = 0;
    for (let positionIndex = 0; positionIndex < realPositions.length; positionIndex++) {
      for (let tripIndex = tripCounter; tripIndex < trips.length; tripIndex++) {
        if (realPositions[positionIndex].fixTime == moment.parseZone(trips[tripIndex].trip_start_fixtime).valueOf()) {
          trips[tripIndex].trip_start_position_index = realPositions[positionIndex].index
        }
        if (realPositions[positionIndex].fixTime == moment.parseZone(trips[tripIndex].trip_end_fixtime).valueOf()) {
          trips[tripIndex].trip_end_position_index = realPositions[positionIndex].index
          tripCounter = tripIndex + 1;
        }
      }
    }
    setTrips(trips);

    var tripTotalsMaxSpeed = 0;
    var tripTotalsTotalTime = 0;
    var tripTotalsTotalKm = 0;
    trips.forEach(trip => {
      tripTotalsMaxSpeed = trip.trip_max_speed > tripTotalsMaxSpeed ? trip.trip_max_speed : tripTotalsMaxSpeed;
      tripTotalsTotalTime += trip.trip_duration;
      tripTotalsTotalKm += trip.trip_distance;
    });
    setTripTotals({
      maxSpeed: tripTotalsMaxSpeed,
      totalTime: tripTotalsTotalTime,
      totalKm: tripTotalsTotalKm,
    });

    fitRouteToMap(realPositions);
    //setShowIcon(false);
    //setAnimation(0)
    //animateRoute();
  }
  
/*  
  useEffect(() => {
    if (animation<=100) {
      setShowIcon(false);
      const interval = setInterval(() => {
        setAnimation(animation+10)
        setIndex([0, Math.max(positions.length*(animation/100) - 1)]);
      }, 100);
      return () => clearInterval(interval);
    } else {
      setShowIcon(true);
    }
  }, [animation]);

  const animateRoute = () => {
    const duration = 2000;
    const startTime = performance.now();
    
    function updateCounter(currentTime) {
      const elapsedTime = currentTime - startTime;
      var progress = Math.min(elapsedTime / duration, 1);
      if (progress<0) progress = 0;
      if (progress>1) progress = 1;
      setIndex([0, Math.max(positions.length*progress - 1)]);
      if (progress < 1) {
        requestAnimationFrame(updateCounter);
      }
    }
    requestAnimationFrame(updateCounter);
    setIndex([0, Math.max(positions.length - 1)]);
    setShowIcon(true);
  }
*/

  function getDevice(deviceId) {
    var result = null;
    devices.forEach(function(device) {
      if (device.id == deviceId) result = device;
    });
    return result
  }

  function showPopup(e) {
    var position = e.features[0];
    var closestGeofence = getClosestGeofence(geofences, position.geometry.coordinates[1], position.geometry.coordinates[0], true, true);

    const placeholder = document.createElement('div');
    placeholder.style.minWidth = "max-content";
    ReactDOM.render(
      <Provider store={store}>
          <DeviceStatusHistoryView
            position={position}
            deviceId={deviceId}
            closestGeofence={closestGeofence}
          />
      </Provider>,
      placeholder,
    );

    popup.setDOMContent(placeholder)
    popup.setLngLat(e.features[0].geometry.coordinates.slice())
    popup.addTo(map)

  }
  
  function hidePointer() {
    map.getCanvas().style.cursor = '';
  }

  function showPointer(e) {
    map.getCanvas().style.cursor = 'pointer';
  }
  
  useEffect(() => {
    document.querySelectorAll('.mapboxgl-popup-wrapper').forEach(item => {
      item.remove();
    })
  }, [index])

  function fitRouteToMap(realPositions) {
    const coordinates = realPositions.map(item => [item.longitude, item.latitude]);
    if (coordinates.length) {
      const bounds = coordinates.reduce((bounds, item) => bounds.extend(item), new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]));
      map.fitBounds(bounds, {
        padding: { top: 50, bottom: 350, left: 25, right: 25 },
      });
    }
  }


  function chartClick(event) {
    try {
      var selectedIndex = getIndexFromDate(event.activePayload[0].payload.fixTime);

      var newIndex = [selectedIndex<index[0] ? 0 : index[0], selectedIndex]

      setIndex(newIndex)
    }
    catch (e) {
    }
  }

  function getIndexFromDate(date) {
    for (var i = 0; i < positions.length - 1; i++) {
      if (positions[i].fixTime == date) {
        return i
      }
    }
  }
  function getTimeFromIndex(index) {
    try {
      return moment(positions[index].fixTime).tz("Etc/UTC").format('HH:mm:ss')
    }
    catch {
      return false;
    }
  }
  function getDateFromIndex(index) {
    try {
      return moment(positions[index].fixTime).tz("Etc/UTC").format('YYYY-MM-DD HH:mm:ss')
    }
    catch {
      return false;
    }
  }
  function getDateFromEpoch(epoch) {
    try {
      return moment(epoch).tz("Etc/UTC").format('YYYY-MM-DD HH:mm:ss')
    }
    catch {
      return false;
    }
  }

  function chartTooltip(value, name, props) {
    if (name == 'speed') return [value + ' km/h', t('speed'), 'ddd']
    if (name == 'attributes.power') return [value + ' ' + (props.payload.attributes.battery ? "%" : "V"), t('battery')]
    if (name == 'attributes.fuel') return [value + ' %' + ( device.attributes.hasOwnProperty('fuel_tank_capacity') ? ' (aprox. ' + Math.round((value/100)*device.attributes.fuel_tank_capacity) + " " + (device.attributes.fuel_type==1 ? "kW" : "L") : ''), t('fuel')]
    if (name == 'attributes.rpm' && value) return [value + ' RPM', t('engine')]
    if (name == 'attributes.temp1') return [value + ' °C', t('temperature')]
    return [null, null]
  }

  const showStatusDialog = (title, message) => {
    setStatusDialog({title: title, message: message});
    setResultStatus(true);
  }

  const hideStatusDialog = () => {
    setResultStatus(false);
  };

  const exportKml = () => {

    var title = device.attributes.license_plate + ' (' + getDateFromIndex(index[0])+ ' - ' + getDateFromIndex(index[1]) + ')';
    var filename = title.replaceAll(':', '.') + '.kml'
    var route = positions.slice(index[0], index[1]).filter(position => position.latitude).map(position => position.longitude + ',' + position.latitude).join(" ")

    var start_position = false;
    var end_position = false;
    var events = '';
    for (let i = index[0]; i < index[1]; i++) {
      if (positions[i].latitude) {
        if (!start_position) start_position = positions[i]
        end_position = positions[i]

        if (positions[i].attributes.event) {
          events += `
          <Placemark>
			      <name>${getDateFromEpoch(positions[i].fixTime)} ${t(positions[i].attributes.event.toLowerCase().replace('event_', ''))}</name>
			      <description>${eventDescription(device, positions[i])}</description>
            <styleUrl>#event_${positions[i].attributes.event}</styleUrl>
            <Point>
              <coordinates>${positions[i].longitude},${positions[i].latitude}</coordinates>
            </Point>
          </Placemark>
          `
        }
      }
    }

    if (start_position && end_position) {

      var device_icon = device.category + "_" + (end_position.attributes.ignition ? (end_position.speed>idleSpeed ? "green" : 'yellow') : "red");

      const event_styles = [
        ['start_position',  'https://www.ecoogps.com/images/start.png', 1, 0],
        ['end_position',    `https://www.ecoogps.com/images/vehicles/${device_icon}.png`, 1, end_position.course],
        ['event_fuel',      'https://www.ecoogps.com/images/event_fuel.png', 0.5, 0],
        ['event_idle',      'https://www.ecoogps.com/images/event_idle.png', 0.5, 0],
        ['event_pto',       'https://www.ecoogps.com/images/event_pto.png', 0.5, 0],
        ['event_speeding',  'https://www.ecoogps.com/images/event_speeding.png', 0.5, 0],
        ['event_stop',      'https://www.ecoogps.com/images/event_stop.png', 0.5, 0],
      ];

      var icon_styles = "";

      event_styles.forEach(event_style => {
        icon_styles += `
          <StyleMap id="${event_style[0]}">
            <Pair>
              <key>normal</key>
              <styleUrl>#${event_style[0]}_normal</styleUrl>
            </Pair>
            <Pair>
              <key>highlight</key>
              <styleUrl>#${event_style[0]}_highlight</styleUrl>
            </Pair>
          </StyleMap>
  
          <Style id="${event_style[0]}_normal">
            <IconStyle>
              <scale>${event_style[2]}</scale>
              <heading>${event_style[3]}</heading>
              <Icon>
                <href>${event_style[1]}</href>
              </Icon>
            </IconStyle>
            <LabelStyle>
              <scale>0</scale>
            </LabelStyle>
          </Style>
          
          <Style id="${event_style[0]}_highlight">
            <IconStyle>
              <scale>${event_style[2]*2}</scale>
              <heading>${event_style[3]}</heading>
              <Icon>
                <href>${event_style[1]}</href>
              </Icon>
            </IconStyle>
            <LabelStyle>
              <scale>1</scale>
            </LabelStyle>
          </Style>
          `;
      })

      var kml = `<?xml version="1.0" encoding="UTF-8"?>
      <kml xmlns="http://www.opengis.net/kml/2.2" xmlns:gx="http://www.google.com/kml/ext/2.2" xmlns:kml="http://www.opengis.net/kml/2.2" xmlns:atom="http://www.w3.org/2005/Atom">
      <Document>
        <name>${title}</name>
        
        <Style id="route">
          <LineStyle>
            <color>ffff0000</color>
            <width>4</width>
            <gx:labelVisibility>1</gx:labelVisibility>
          </LineStyle>
        </Style>

        ${icon_styles}
        
        <Placemark>
          <name>${t('route')}</name>
          <styleUrl>#route</styleUrl>
          <LineString>
            <tessellate>1</tessellate>
            <coordinates>${route}</coordinates>
          </LineString>
        </Placemark>
  
        <Folder>
          <name>${t('events')}</name>
          <open>0</open>
  
          <Placemark>
            <name>${getDateFromEpoch(start_position.fixTime)} ${t('start')}</name>
            <description>${t('start')}</description>
            <styleUrl>#start_position</styleUrl>
            <Point>
              <coordinates>${start_position.longitude},${start_position.latitude}</coordinates>
            </Point>
          </Placemark>
  
          ${events}
          
          <Placemark>
            <name>${getDateFromEpoch(end_position.fixTime)} ${t('end')}</name>
            <description>${t('end')}</description>
            <styleUrl>#end_position</styleUrl>
            <Point>
              <coordinates>${end_position.longitude},${end_position.latitude}</coordinates>
            </Point>
          </Placemark>
  
        </Folder>
      </Document>
      </kml>`;
  
      downloadFile(filename, "text/plain", kml);
    }
  };

  const downloadFile = (name, mime, content) => {
    const blob = new Blob([content], { type: mime });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = name;
    link.href = url;
    link.click();
  };

  const fuelLevelVariation = (positions) => {
    var initialFuel = -1;
    var finalFuel = -1;
    positions.filter(value => value.hasOwnProperty('attributes')).filter(value => value.attributes.hasOwnProperty('fuel')).forEach(function(position) {
      if (initialFuel == -1) initialFuel = position.attributes.fuel;
      finalFuel = position.attributes.fuel;
    });

    if (initialFuel!=-1 && finalFuel!= -1) {

      var percentage = Math.abs(finalFuel-initialFuel).toFixed(0)
      var litres = false;
      if (device.hasOwnProperty('attributes') && device.attributes.hasOwnProperty('fuel_tank_capacity')) {
        litres = ((Math.abs(finalFuel-initialFuel)/100)*device.attributes.fuel_tank_capacity).toFixed(0)
      }

      if ((finalFuel-initialFuel) == 0) {
        return " -"
      }

      if ((finalFuel-initialFuel) > 0) {
        return (
          <>
            <FontAwesomeIcon icon={faCaretUp} style={{color: '#00bb00', marginRight: '3px', fontSize: '1.1rem'}} />
            {percentage} %
            { litres &&
              <> (aprox. {litres} {device.attributes.fuel_type==1 ? "kW" : "L"})
              </>
            }
          </>
        )
      } else {
        return (
          <>
            <FontAwesomeIcon icon={faCaretDown} style={{color: '#ff0000', marginRight: '3px', fontSize: '1.1rem'}} />
            {percentage} %
            { litres &&
              <> (aprox. {litres} {device.attributes.fuel_type==1 ? "kW" : "L"})
              </>
            }
          </>
        )
      }
    }

    return "-"
  }

  const temperatureVariation = (positions) => {

    var min = 50;
    var max = -50;

    var samples = 0;
    var average = 0;

    positions.filter(value => value.hasOwnProperty('attributes')).filter(value => value.attributes.hasOwnProperty('temp1')).forEach(function(position) {
      
      samples = samples + 1
      average = average + position.attributes.temp1

      if (position.attributes.temp1 < min)
        min = position.attributes.temp1
      if (position.attributes.temp1 > max)
        max = position.attributes.temp1
    });

    if (samples == 0) {
      return "-"
    } else {
      return (
        <>
          <span style={{color: '#0000cc'}}>
            Min: {min} °C
          </span> / <span style={{color: '#000000'}}>
            Med: {(average/samples).toFixed(1)} °C
          </span> / <span style={{color: '#cc0000'}}>
            Max: {max} °C
          </span>
        </>
      )      
    }
  }

  const fuelConsumptionVariation = (positions) => {
    var consumption = 0;

    positions.filter(value => value.hasOwnProperty('attributes')).filter(value => value.attributes.hasOwnProperty('consumption')).forEach(function(position) {
      consumption += position.attributes.consumption;
    });

    if (consumption > 0) {
      return t('consumption') + ": " + consumption.toFixed(1) + " L / ";
    }
    return "";
  }

  const distance = (positions) => {
    var distance = 0;
    positions.filter(value => value.hasOwnProperty('distance')).forEach(function(position) {
      distance += position.distance;
    });
    return distance;
  }

  const maxSpeed = (positions) => {
    var speed = 0;
    positions.filter(value => value.hasOwnProperty('speed')).forEach(function(position) {
      if (position.speed>speed) speed = position.speed
    });
    return speed;
  }

  const stops = (positions) => {
    var stops = 0;
    positions.filter(value => value.hasOwnProperty('attributes')).forEach(function(position) {
      if (position.attributes.event == "stop") {
        stops += 1;
      }
    });
    return stops;
  }

  const [animationSpeed, setAnimationSpeed] = useState(0);
  const [tick, setTick] = useState(false);

  const nextButton = () => {
    if (animationSpeed>0) {
      setAnimationSpeed(animationSpeed => animationSpeed<4 ? animationSpeed+1 : 4);
    } else {
      nextMinute();
    }
  }

  const nextMinute = () => {
    setIndex([index[0], index[1] + 60 <= positions.length-1 ? index[1] + 60 : 0]);
  }

  const prevButton = () => {
    if (animationSpeed>0) {
      setAnimationSpeed(animationSpeed => animationSpeed-1);
    } else {
      prevMinute();
    }
  }

  const prevMinute = () => {
    setIndex([index[0], index[1] - 60 >= 0 ? index[1] - 60 : 0]);
  }

  const animationStartStop = () => {
    setAnimationSpeed(animationSpeed>0 ? 0 : 1);
  }

  const handleSetChatType = (event, value) => {
    if (value.length>0) setChartType(value)
  }
  
  useEffect(() => {
    if (animationSpeed) {
      nextMinute();
      const timer = setTimeout(() => {
        setTick(tick => !tick);
      }, 1000/animationSpeed);
    }
  }, [animationSpeed, tick]);

  const selectTrip = (trip) => {
    if (trip) {
      setIndex([trip.trip_start_position_index, trip.trip_end_position_index])
      fitRouteToMap(positions.filter(position => position.latitude && position.longitude && position.index >= trip.trip_start_position_index && position.index <= trip.trip_end_position_index));
    } else {
      setIndex([0, positions.length-1])
      fitRouteToMap(filterRealPositions(positions))
    }
  }

  const tripVisible = (trip) => {
    return trip.trip_start_position_index >= index[0] && trip.trip_end_position_index <= index[1]
      || trip.trip_start_position_index <= index[0] && trip.trip_end_position_index >= index[0]
      || trip.trip_start_position_index <= index[1] && trip.trip_end_position_index >= index[1]
  }

  const shortAddress = (address) => {
    return address ? address.replace('Avenida ', 'Av. ') : address;
  }

  return (
    <div className={classes.root}>
      <MainToolbar mapView={true}/>

    <div className={classes.mapContainer}>
      <Map>
        <ReplayPathMap positions={positions} index={index} device={device} showIcon={showIcon} />
        <GeofenceMap />
        <TrafficSignsMap />
      </Map>
    </div>


      <Dialog open={resultStatus} onClose={hideStatusDialog}>
        <DialogTitle id="form-dialog-title">
          {statusDialog.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText component="span">
            <Grid container spacing={1}>
              <Grid item>
                <img src="images/emoji_worried_face.png" width="32px"/>
              </Grid>
              <Grid item style={{ marginTop: '4px' }}>
                <Box>
                  {statusDialog.message}
                </Box>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={hideStatusDialog} color="primary" variant="outlined">OK</Button>
        </DialogActions>
      </Dialog>

      {!!positions.length && showChart &&

        <div
          className={classes.showInLandscape}
          style={{
            position: 'absolute',
            borderRadius: '10px',
            bottom: mainContainerHeight + 30 + 10,
            left: '1%',
            height: pageHeight - 40 - 30 - mainContainerHeight - 10 - 10,
            width: '350px',
            overflow: 'auto',
            paddingRight: '10px',
            }}>

            <List className={classes.list}>

              <ListItem button key={'trip_total'} style={{
                paddingLeft: '10px',
                paddingRight: '10px',
                backgroundColor: 'rgba(255, 255, 255, 0.80)',
                borderRadius: '20px',
                color: 'black',
                marginBottom: '20px',
                marginTop: '5px',
                boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px'
              }}>
                
                <ListItemText onClick={() => selectTrip(false)}>

                  <div style={{
                    position: 'absolute',
                    backgroundColor: '#78A9FF',
                    color: 'white',
                    borderRadius: '10px',
                    top: '-10px',
                    left: '20px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    fontSize: '0.8em',
                  }}>
                    {t('totalActivity') + ": " + trips.length + " " + (trips.length == 1 ? t('trip').toLowerCase() : t('trips').toLowerCase())}
                  </div>
                  
                  <Typography variant="caption" style={{display: 'block', whiteSpace: 'nowrap', overflow: 'hidden'}} component={'span'}>

                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div style={{ display: 'inline', width: '70px'}}>
                        <FontAwesomeIcon icon={faTachometerAlt} style={{color: '#78A9FF'}}/>
                        &nbsp;
                        <span style={{fontSize: '1.5em', fontWeight: 'bold'}}>
                          { tripTotals.maxSpeed }
                        </span>&nbsp;km/h
                      </div>

                      <div style={{ display: 'inline'}}>
                        <FontAwesomeIcon icon={faStopwatch} style={{color: '#78A9FF'}}/>
                        &nbsp;
                        <span style={{fontSize: '1.5em', fontWeight: 'bold'}}>
                          { getHMS(tripTotals.totalTime) }
                        </span>
                      </div>

                      <div style={{ display: 'inline', width: '90px'}}>
                        <FontAwesomeIcon icon={faRoad} style={{color: '#78A9FF'}}/>
                        &nbsp;
                        <span style={{fontSize: '1.5em', fontWeight: 'bold'}}>
                          { isNaN(tripTotals.totalKm) ? 0 : Math.round(tripTotals.totalKm /100)/10 }
                        </span>&nbsp;km
                      </div>
                    </div>
                    
                  </Typography>

                </ListItemText>

              </ListItem>

              {trips.map((trip, index, list) => (

                  <ListItem button key={'trip_'+index} style={{
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    backgroundColor: tripVisible(trip) ? 'rgba(255, 255, 255, 0.80)' : 'rgba(235, 235, 235, 0.80)',
                    borderStyle: false ? 'solid' : 'none',
                    borderColor: '#78A9FF',
                    borderWidth: '2px',
                    borderRadius: '20px',
                    color: 'black',
                    marginBottom: '20px',
                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px'
                  }}>
                    
                    <ListItemText onClick={() => !trip.trip_privacy ? selectTrip(trip) : null}>

                      <div style={{
                        position: 'absolute',
                        backgroundColor: tripVisible(trip) ? '#eb8f3a' : "#777777",
                        color: 'white',
                        borderRadius: '10px',
                        top: '-10px',
                        left: '20px',
                        paddingLeft: '10px',
                        paddingRight: '10px',
                        fontSize: '0.8em',
                      }}>
                        {t('trip')} nº {index+1}
                      </div>
                      
                      <Typography variant="caption" style={{display: 'block', whiteSpace: 'nowrap', overflow: 'hidden'}} component={'span'}>

                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <div style={{ display: 'inline', width: '70px'}}>
                            <FontAwesomeIcon icon={faTachometerAlt} style={{color: '#78A9FF'}}/>
                            &nbsp;
                            <span style={{fontSize: '1.5em', fontWeight: 'bold'}}>
                              { trip.trip_max_speed }
                            </span>&nbsp;km/h
                          </div>

                          <div style={{ display: 'inline'}}>
                            <FontAwesomeIcon icon={faStopwatch} style={{color: '#78A9FF'}}/>
                            &nbsp;
                            <span style={{fontSize: '1.5em', fontWeight: 'bold'}}>
                              { new Date(trip.trip_duration * 1000).toISOString().slice(11, 19) }
                            </span>
                          </div>

                          <div style={{ display: 'inline', width: '90px'}}>
                            <FontAwesomeIcon icon={faRoad} style={{color: '#78A9FF'}}/>
                            &nbsp;
                            <span style={{fontSize: '1.5em', fontWeight: 'bold'}}>
                              { Math.round(trip.trip_distance / 100) / 10 }
                            </span>&nbsp;km
                          </div>
                        </div>

                        { !trip.trip_privacy &&
                          <>
                            <div style={{color: 'rgb(35, 150, 35)'}}>
                              <span style={{minWidth: '20px', display: 'inline-block'}}>
                                <FontAwesomeIcon icon={faFlag}/>
                              </span>
                              <span style={{minWidth: '50px', display: 'inline-block'}}>
                                { trip.trip_start_fixtime.substring(11) }
                              </span>
                              <span>
                                { shortAddress(trip.trip_start_address) }
                              </span>
                            </div>

                            <div style={{color: 'rgb(150, 35, 35)'}}>
                              <span style={{minWidth: '20px', display: 'inline-block'}}>
                                <FontAwesomeIcon icon={faFlagCheckered} style={{color: 'black'}}/>
                              </span>
                              <span style={{minWidth: '50px', display: 'inline-block'}}>
                                { trip.trip_end_fixtime.substring(11) }
                              </span>
                              <span>
                                { shortAddress(trip.trip_end_address) }
                              </span>
                            </div>
                          </>
                        }

                        { !!trip.trip_privacy &&
                          <div style={{color: 'rgb(0, 0, 0)'}}>
                            <span style={{minWidth: '20px', display: 'inline-block'}}>
                              <FontAwesomeIcon icon={faUserSecret} style={{color: '#78A9FF'}}/>
                            </span>
                            <span style={{minWidth: '50px', display: 'inline-block'}}>
                              { t('private') }
                            </span>
                          </div>
                        }

                        { trip.trip_driver_uniqueid &&
                          <div style={{color: 'rgb(0, 0, 0)'}}>
                            <span style={{minWidth: '20px', display: 'inline-block'}}>
                              <FontAwesomeIcon icon={faUser}  style={{color: '#78A9FF'}}/>
                            </span>
                            <span>
                              { trip.trip_driver_uniqueid }
                            </span>
                          </div>
                        }

                        { (trip.trip_ptos>0 || trip.trip_refills>0) &&
                          <div style={{ textAlign: 'right', paddingRight: '10px', height: '20px' }}>
                            { trip.trip_ptos>0 &&
                              <span style={{ paddingLeft: '10px' }}>
                                <img src="images/event_pto.png" height={20} />
                                <span style={{ position: 'relative', top: '-5px'}}>{trip.trip_ptos>1 ? " x" + trip.trip_ptos : ""}</span>
                              </span>
                            }
                            { trip.trip_refills>0 &&
                              <span style={{ paddingLeft: '10px' }}>
                                <img src="images/event_fuel.png" height={20} />
                                <span style={{ position: 'relative', top: '-5px'}}>{trip.trip_refills>1 ? " x" + trip.trip_refills : ""}</span>
                              </span>
                            }
                          </div>
                        }
                      </Typography>

                    </ListItemText>

                  </ListItem>
              ))}

            </List>

        </div>      
      }

      <Container
        ref={main_container}
        className={classes.controlPanel}
        style={{
          paddingTop: '5px',
          paddingBottom: '5px',
          paddingLeft: '15px',
          paddingRight: '15px',
          backgroundColor: 'rgba(255, 255, 255, 0.88)',
          borderRadius: '10px',
          maxWidth: '98%',
          textAlign: 'center',
          width: !!positions.length ? '98%' : 'auto'
        }}
      >

        <div style={{display: 'inline-block', paddingBottom: '10px', textAlign: 'center', width: '100%'}}>
            

            { !!positions.length &&
              <div style={{ marginTop: '12px', marginRight: '20px', display: 'inline-flex' }} className={classes.positionLeftInLandscape}>
                <Tooltip title={showChart ? t('chartHide') : t('chartShow')}>
                  <Button
                    color="primary"
                    onClick={handleToggleChart}
                    variant="outlined"
                  >
                    <FontAwesomeIcon icon={faChartArea} style={{ fontSize: '1.5em'}} />
                    {showChart && <FontAwesomeIcon icon={faSlash} style={{position: 'absolute', fontSize: '1.5em', color: 'red'}} />}
                  </Button>
                </Tooltip>
                <Tooltip title={t('print')}>
                  <Button
                    color="primary"
                    onClick={() => { window.print(); }}
                    variant="outlined"
                    style={{ marginLeft: '10px' }}
                  >
                    <FontAwesomeIcon icon={faPrint} style={{ fontSize: '1.5em'}} />
                  </Button>
                </Tooltip>
                <Tooltip title={t('exportKml')}>
                  <Button
                    color="primary"
                    onClick={exportKml}
                    variant="outlined"
                    style={{ marginLeft: '10px' }}
                  >
                    <FontAwesomeIcon icon={faGlobeAfrica} style={{ fontSize: '1.5em'}} />
                  </Button>
                </Tooltip>
              </div>
            }

            <div
              style={{
                display: 'inline-flex',
                verticalAlign: 'top',
                marginRight: '20px',
                minWidth: '300px',
              }}>
              
              <Autocomplete
                options={devices.sort((a, b) => a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1)}
                onChange={(event, item) => { setAutocomplete(item); global.replay.device = item; setDeviceId(item.id) }}
                value={autocomplete}
                getOptionSelected={(option, value) => option.id === value.id}

                disableClearable={true}
                disableListWrap={true}
                fullWidth={true}

                style={{ verticalAlign: 'top' }}

                getOptionLabel={(device) => getDeviceName(device)}

                ListboxProps={{
                  style: {
                    minHeight: pageHeight/2
                  },
                }}

                renderInput={(params) => 
                  <TextField {...params} label={t('vehicle')} />
                }

                renderOption={(device) => (
                  <React.Fragment>
                    <span>
                      <img width="25" src={ getDeviceIconPath(device.category, false) } style={{ marginRight: '5px', position: 'relative', top: '5px' }} />
                      {getDeviceName(device)}
                    </span>
                  </React.Fragment>
                )}
              />
            </div>

            <FormControl fullWidth className={classes.FormControl} style={{ minWidth: '100px', width: 'auto', marginRight: '20px' }}>
              <InputLabel>{t('period').replace(/ /g, "\u00a0")}</InputLabel>
              <Select value={period} onChange={(e) => { setPeriod(e.target.value); global.replay.period = e.target.value }}>
                <MenuItem key='today' value="today">{t('reportToday')}</MenuItem>
                <MenuItem key='yesterday' value="yesterday">{t('reportYesterday')}</MenuItem>
                <MenuItem key='dayBeforeYesterday' value="dayBeforeYesterday">{t('dayBeforeYesterday')}</MenuItem>
                <MenuItem key='thisWeek' value="thisWeek">{t('reportThisWeek')}</MenuItem>
                <MenuItem key='previousWeek' value="previousWeek">{t('reportPreviousWeek')}</MenuItem>
                <MenuItem key='custom' value="custom">{t('reportPickDates')}</MenuItem>
              </Select>
            </FormControl>
            
          {period === 'custom' && (
          <>

              <FormControl fullWidth className={classes.FormControl} style={{ minWidth: '100px', width: 'auto', marginRight: '20px' }}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DateTimePicker
                    label={t('from')}
                    format="DD/MM/yyyy HH:mm:ss"
                    ampm={false}
                    variant="dialog"
                    value={from}
                    onChange={e => { setFrom(e); global.replay.from = e }}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
              
              <FormControl fullWidth className={classes.FormControl} style={{ minWidth: '100px', width: 'auto', marginRight: '20px' }}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DateTimePicker
                    label={t('to')}
                    format="DD/MM/yyyy HH:mm:ss"
                    ampm={false}
                    variant="dialog"
                    value={to}
                    onChange={e => { setTo(e); global.replay.to = e }}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
          </>
          )}
          
            <ButtonGroup color="primary" orientation="horizontal" disabled={!autocomplete} style={{ width: 'auto', marginRight: '20px' }}>
              <Button
                onClick={() => handleSubmit()}
                style={{marginTop: '12px', whiteSpace: 'nowrap'}}>
                  <SearchIcon />&nbsp;
                {t('query')}
              </Button>
            </ButtonGroup>
            
            { loading &&
              <div style={{ display: 'inline', top: '10px', left: '10px', position: 'relative', width: 'auto', marginRight: '20px' }}>
                <CircularProgress disableShrink={true} size={30} />
              </div>
            }
            
          <span style={{ position: 'absolute', right: '0px', top: '-40px'}}>
            <Tooltip title={t('backToOnlineMap')}>
              <Button
                aria-label="Apagar filtro"
                onClick={() => history.push({pathname: `/`})}
                variant="outlined"
                style={{ color: '#ff0000', backgroundColor: '#ffffffcc', fontSize: '0.9em', fontWeight: 'bold', whiteSpace: 'nowrap' }}
              >
                <FontAwesomeIcon icon={faReply} />
                &nbsp;{t('backToOnlineMap')}
              </Button>
            </Tooltip>
          </span>          
        </div>


        {!!positions.length && showChart &&
          <>

            <div style={{display: 'flex'}}>

              <div id='buttons' style={{float: 'left'}}>
                <ToggleButtonGroup
                  orientation="vertical"
                  value={chartType}
                  onChange={handleSetChatType}
                >
                    <ToggleButton value="speed">
                      <Tooltip title="Gráfico de velocidade" placement="right">
                        <span style={{display: 'flex'}}>
                          <FontAwesomeIcon icon={faTachometerAlt}  style={{color: chartColorSpeed}}/>
                        </span>
                      </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="attributes.power">
                      <Tooltip title="Gráfico de nível de bateria" placement="right">   
                        <span style={{display: 'flex'}}>
                          <FontAwesomeIcon icon={faCarBattery}  style={{color: chartColorPower}}/>
                        </span>
                      </Tooltip>
                    </ToggleButton>
                    { hasFuel && (
                      <ToggleButton value="attributes.fuel">
                        <Tooltip title={"Gráfico de nível de combustível" + (device && device.attributes && device.attributes.fuelsensor_port > 0 ? " (Sensor analógico)" : " (CAN-Bus)")} placement="right">                    
                          <span style={{display: 'flex'}}>
                            <FontAwesomeIcon icon={device.attributes && device.attributes.fuel_type==1 ? faChargingStation : faGasPump}  style={{color: chartColorFuel}}/>
                          </span>
                        </Tooltip>
                      </ToggleButton>
                      )
                    }

                    { maxRpmFound>0 && (
                        <ToggleButton value="attributes.rpm">
                          <Tooltip title={"Gráfico de RPM"} placement="right">                    
                            <span style={{display: 'flex'}}>
                              <EngineIcon
                                viewBox="0 50 140 1"
                                style={{color: chartColorRpm, fontSize: '20px'}}
                                />
                            </span>
                          </Tooltip>
                        </ToggleButton>
                      )
                    }

                    { hasTemperature && (
                      <ToggleButton value="attributes.temp1">
                        <Tooltip title="Gráfico de temperatura" placement="right">                    
                          <span style={{display: 'flex'}}>
                            <FontAwesomeIcon icon={faThermometerFull}  style={{color: chartColorTemperature}}/>
                          </span>
                        </Tooltip>
                      </ToggleButton>
                      )
                    }
                </ToggleButtonGroup>
              </div>

              <div style={{float: 'right', width: '100%'}}>
                <div id='chart'>
                  <ResponsiveContainer
                    width="100%"
                    height={150}
                  >

                    <ComposedChart
                      data={filterRealPositions(positions)}
                      onClick={(event) => chartClick(event)}
                      style={{fontSize: '0.80em'}}
                      margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                    >
                      <CartesianGrid
                        stroke="#ccc"
                      />

                      <XAxis
                        dataKey = 'fixTime'
                        name = 'Time'
                        tickFormatter = {(unixTime) => moment(unixTime).tz("Etc/UTC").format('H:mm')}
                        type = 'number'
                        style={{userSelect: 'none'}}
                        tickCount = {20}
                        domain={[minTime, maxTime]}
                      />


                      <YAxis
                        hide = {!chartType.includes('speed')}
                        yAxisId='speed'
                        orientation="left"
                        stroke={chartColorSpeed}
                        //label= {{ value: 'Velocidade', angle: -90, position: 'outsideCenter' }}
                        style={{userSelect: 'none'}}
                        domain={[0, maxSpeedFound]}
                        allowDataOverflow={true}
                        dataKey='speed'
                        width={35}
                      />
                      { chartType.includes('speed') &&
                        <Area
                          yAxisId='speed'
                          type="monotone"
                          isAnimationActive={false}
                          dot={false}
                          dataKey='speed'
                          stroke={chartColorSpeed}
                          strokeWidth={2}
                          fill={chartColorSpeed}
                          fillOpacity={0.8}
                        />
                      }
                      
                      <YAxis
                        hide = {!chartType.includes('attributes.power')}
                        yAxisId='attributes.power'
                        orientation={!chartType.includes('speed') ? "left" : "right"}
                        stroke={chartColorPower}
                        style={{userSelect: 'none'}}
                        domain={[0, maxPowerFound]}
                        allowDataOverflow={true}
                        dataKey='attributes.power'
                        width={35}
                      />
                      
                      { chartType.includes('attributes.power') &&
                        <Line
                          yAxisId='attributes.power'
                          type="monotone"
                          isAnimationActive={false}
                          dot={false}
                          dataKey='attributes.power'
                          stroke={chartColorPower}
                          strokeWidth={2}
                        />
                      }
                      
                      <YAxis
                        hide = {!(chartType.includes('attributes.fuel') && hasFuel)}
                        yAxisId='attributes.fuel'
                        orientation={!chartType.includes('speed') && !chartType.includes('attributes.power') ? "left" : "right"}
                        stroke={chartColorFuel}
                        style={{userSelect: 'none'}}
                        domain={[0, 100]}
                        allowDataOverflow={true}
                        dataKey='attributes.fuel'
                        width={35}
                      />
                      
                      { chartType.includes('attributes.fuel') &&
                        <Line
                          yAxisId='attributes.fuel'
                          type="monotone"
                          isAnimationActive={false}
                          dot={false}
                          dataKey='attributes.fuel'
                          stroke={chartColorFuel}
                          strokeWidth={2}
                        />
                      }
                      
                      <YAxis
                        hide = {!(chartType.includes('attributes.rpm') && maxRpmFound>0)}
                        yAxisId='attributes.rpm'
                        orientation={!chartType.includes('speed') && !chartType.includes('attributes.power') && !(chartType.includes('attributes.fuel') && hasFuel) ? "left" : "right"}
                        stroke={chartColorRpm}
                        style={{userSelect: 'none'}}
                        domain={[0, maxRpmFound]}
                        allowDataOverflow={true}
                        dataKey='attributes.rpm'
                        width={35}
                      />
                      { chartType.includes('attributes.rpm') && maxRpmFound>0 &&
                        <Line
                          yAxisId='attributes.rpm'
                          type="monotone"
                          isAnimationActive={false}
                          dot={false}
                          dataKey='attributes.rpm'
                          stroke={chartColorRpm}
                          strokeWidth={2}
                        />
                      }
                      
                      <YAxis
                        hide = {!chartType.includes('attributes.temp1')}
                        yAxisId='attributes.temp1'
                        orientation={!chartType.includes('speed') && !chartType.includes('attributes.power') && !(chartType.includes('attributes.fuel') && hasFuel) && !(chartType.includes('attributes.rpm') && maxRpmFound>0) ? "left" : "right"}
                        stroke={chartColorTemperature}
                        style={{userSelect: 'none'}}
                        domain={[minTemperature, maxTemperature]}
                        allowDataOverflow={true}
                        dataKey='attributes.fuel'
                        width={35}
                      />
                      
                      { chartType.includes('attributes.temp1') &&
                        <Line
                          yAxisId='attributes.temp1'
                          type="monotone"
                          isAnimationActive={false}
                          dot={false}
                          dataKey='attributes.temp1'
                          stroke={chartColorTemperature}
                          strokeWidth={2}
                        />
                      }

                      <YAxis
                        hide = {true}
                        yAxisId='ignitionOff'
                        domain={[0, 100]}
                        dataKey='ignitionOff'
                      />
                      <Line
                        yAxisId='ignitionOff'
                        dot={false}
                        dataKey='ignitionOff'
                        stroke={chartColorStopped}
                        strokeWidth={4}
                      />

                      <YAxis
                        hide = {true}
                        yAxisId='ignitionIdle'
                        domain={[0, 100]}
                        dataKey='ignitionIdle'
                      />
                      <Line
                        yAxisId='ignitionIdle'
                        dot={false}
                        dataKey='ignitionIdle'
                        stroke={chartColorIdle}
                        strokeWidth={4}
                      />
                      
                      <YAxis
                        hide = {true}
                        yAxisId='ignitionDriving'
                        domain={[0, 100]}
                        dataKey='ignitionDriving'
                      />
                      <Line
                        yAxisId='ignitionDriving'
                        dot={false}
                        dataKey='ignitionDriving'
                        stroke={chartColorDriving}
                        strokeWidth={4}
                      />
                      
                      <RechartTooltip
                        labelFormatter = {(unixTime) => moment(unixTime).tz("Etc/UTC").format('DD/MM/YYYY HH:mm:ss')}
                        formatter = {(value, name, props) => chartTooltip(value, name, props)}
                        contentStyle = {{backgroundColor: '#dddddde0', borderRadius: '10px'}}
                      />

 
                      <ReferenceLine
                        yAxisId='speed'
                        x={typeof positions[index[0]] !== 'undefined' ? positions[index[0]].fixTime : 0}
                        stroke={colorSliderLeft}
                        strokeWidth={2}
                      />

                      <ReferenceLine
                        yAxisId='speed'
                        x={typeof positions[index[1]] !== 'undefined' ? positions[index[1]].fixTime : 0}
                        stroke={colorSliderRight}
                        strokeWidth={2}
                      />

                      { chartType.includes('speed') && !isNaN(device.attributes.speedLimit) &&
                        <ReferenceLine
                          yAxisId='speed'
                          y={device.attributes.speedLimit*1.852}
                          stroke={chartColorSpeed}
                          strokeWidth={1}
                          strokeDasharray="5 5"
                          label={chartType.includes('speed') && chartType.length == 1 ? { position: 'insideTopLeft',  value: t('limit') + ': ' + Math.round(device.attributes.speedLimit*1.852) + ' km/h', fill: '#000000', fontSize: 12, fontWeight: 'bold' } : {}}
                        />
                      }            
                      
                    </ComposedChart>

                  </ResponsiveContainer>
                </div>
                <div
                  id='slider'
                  style={{
                    marginLeft: 33,
                    marginRight: (
                      (chartType.includes('attributes.power') ? (!chartType.includes('speed') ? 0 : 1) : 0)
                      + (chartType.includes('attributes.fuel') && hasFuel ? (!chartType.includes('speed') && !chartType.includes('attributes.power') ? 0 : 1) : 0)
                      + (chartType.includes('attributes.rpm') && maxRpmFound>0 ? (!chartType.includes('speed') && !chartType.includes('attributes.power') && !(chartType.includes('attributes.fuel') && hasFuel) ? 0 : 1) : 0)
                      + (chartType.includes('attributes.temp1') ? (!chartType.includes('speed') && !chartType.includes('attributes.power') && !(chartType.includes('attributes.fuel') && hasFuel) && !(chartType.includes('attributes.rpm') && maxRpmFound>0) ? 0 : 1) : 0)
                      )*35
                  }}
                  >
                  <Slider
                    classes={{
                      thumb: classes.thumb,
                      rail: classes.rail,
                      track: classes.track
                    }}
                    max={positions.length - 1}
                    value={index}
                    onChange={(event, index) => handleSliderChange(index)}
                    valueLabelDisplay="off"
                  />

                </div>
              </div>
            </div>

            <div id='footer' style={{display: 'block'}}>

              <div style={{ fontWeight: 'bold', float: 'left' }} className={classes.fullWidthInPortrait}>
                <FontAwesomeIcon icon={faBackward} style={{color: '#78A9FF', cursor: 'pointer'}} onClick={prevButton}/>
                
                <div
                  onClick={animationStartStop}
                  style={{color: '#78A9FF', cursor: 'pointer', display: 'inline', marginLeft: '15px'}}>
                    { animationSpeed == 0 &&
                      <FontAwesomeIcon icon={faPlay}/>
                    }
                    { animationSpeed > 0 &&
                      <FontAwesomeIcon icon={faPause}/>
                    }
                </div>

                { animationSpeed > 0 &&
                  <span style={{ color: '#000000', fontSize: '1rem', marginLeft: '15px'}}>
                    {animationSpeed}x
                  </span>
                }

                <FontAwesomeIcon icon={faForward} style={{color: '#78A9FF', cursor: 'pointer', marginLeft: '15px'}} onClick={nextButton}/>

                <FontAwesomeIcon icon={faClock} style={{color: '#eb8f3a', marginLeft: '15px'}}/>&nbsp;
                <span style={{ width: '140px', display: 'inline-block', textAlign: 'left' }}>
                  {
                    getTimeFromIndex(index[0]) + ' » ' + getTimeFromIndex(index[1])
                  }
                </span>
              </div>

              <div style={{ fontWeight: 'bold', float: 'right' }} className={classes.fullWidthInPortrait}>

                { hasTemperature && chartType.includes('attributes.temp1') &&
                  <>
                    <FontAwesomeIcon icon={faThermometerFull} style={{color: '#eb8f3a', marginLeft: '15px', marginRight: '5px'}}/>
                    {
                      temperatureVariation(positions.slice(index[0], index[1]))
                    }                 
                  </>
                }

                { hasFuel && chartType.includes('attributes.fuel') &&
                  <>
                    <FontAwesomeIcon icon={device.attributes && device.attributes.fuel_type==1 ? faChargingStation : faGasPump} style={{color: '#eb8f3a', marginLeft: '15px'}}/>
                    &nbsp;
                    {fuelConsumptionVariation(positions.slice(index[0], index[1]))}

                    {t('level')}:&nbsp;
                    {
                      fuelLevelVariation(positions.slice(index[0], index[1]))
                    }                  
                  </>
                }
                
                <FontAwesomeIcon icon={faRoad} style={{color: '#eb8f3a', marginLeft: '15px'}}/>&nbsp;
                {
                  (distance(positions.slice(index[0], index[1]))/1000).toFixed(1)
                } Km
                <FontAwesomeIcon icon={faTachometerAlt}  style={{color: '#eb8f3a', marginLeft: '15px'}}/>&nbsp;
                {
                  maxSpeed(positions.slice(index[0], index[1]))
                } Km/h
                <FontAwesomeIcon icon={faWalking} style={{color: '#eb8f3a', marginLeft: '15px'}}/>&nbsp;
                {
                  stops(positions.slice(index[0], index[1]))
                } {stops(positions.slice(index[0], index[1])) == 1 ? t('stop') : t('stops')}
              </div>

            </div>
          </>
        }
      </Container>
    </div>
  );

  function handleSliderChange(index) {
    setAnimationSpeed(0)
    setIndex(index)
  }

  function handleToggleChart() {
    setShowChart(!showChart)
  }

  function filterRealPositions(positions) {
    return positions.filter(value => value.hasOwnProperty('longitude'))
  }

  function getDevice(deviceId) {
    var result = null;
    devices.forEach(function(device) {
      if (device.id == deviceId) result = device;
    });
    return result
  }
}

export default ReplayPage;