import wellknown from 'wellknown';
import { poiCategories } from '../common/poiCategories';
import { deviceCategories2D, deviceCategories3DStatic, deviceCategories3D } from '../common/deviceCategories';
import t from '../common/localization'
import { renderToString } from 'react-dom/server'
import { formatTimeAgo, formatDate, formatBigNumber } from '../common/formatter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCarBattery } from '@fortawesome/free-solid-svg-icons'
import { faGasPump } from '@fortawesome/free-solid-svg-icons'
import { faRoad } from '@fortawesome/free-solid-svg-icons'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'
import { faLocationArrow } from '@fortawesome/free-solid-svg-icons'
import { faClock } from '@fortawesome/free-solid-svg-icons'
import { faArrowCircleUp } from '@fortawesome/free-solid-svg-icons'
import { faHandPaper } from '@fortawesome/free-solid-svg-icons'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment';
import * as turf from "@turf/turf";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';

export const idleSpeed = 3;

export const loadImage = (url) => {
  return new Promise(imageLoaded => {
    const image = new Image();
    image.onload = () => imageLoaded(image);
    image.src = url;
  });
};

export const loadIcon = async (file, iconZoom = 1) => {
  //console.log(`Loading icon from images/vehicles/${file}.png`)
  const image = await loadImage(`images/${file}`);
  const pixelRatio = window.devicePixelRatio;

  const iconSize = 45 * pixelRatio * iconZoom
  const canvas = document.createElement('canvas');
  canvas.width = iconSize;
  canvas.height = iconSize;

  const context = canvas.getContext('2d');
  context.drawImage(image, 0, 0, canvas.width, canvas.height);

  return context.getImageData(0, 0, canvas.width, canvas.height);
};

export const loadSprite = (sprite, deltaX, deltaY, iconSize, iconZoom = 1) => {
  const pixelRatio = window.devicePixelRatio;
  const finalIconSize = 45 * pixelRatio * iconZoom
  const canvas = document.createElement('canvas');
  canvas.width = finalIconSize;
  canvas.height = finalIconSize;
  const context = canvas.getContext('2d');
  context.drawImage(sprite, deltaX, deltaY, iconSize, iconSize, 0, 0, finalIconSize, finalIconSize);

  return context.getImageData(0, 0, finalIconSize, finalIconSize);

};

export const reverseCoordinates = it => {
  if (!it) {
    return it;
  } else if (Array.isArray(it)) {
    if (it.length === 2 && !Number.isNaN(it[0]) && !Number.isNaN(it[1])) {
      return [it[1], it[0]];
    } else {
      return it.map(it => reverseCoordinates(it));
    }
  } else {
    return {
      ...it,
      coordinates: reverseCoordinates(it.coordinates),
    }
  }
}

export const geofenceToFeature = (item) => {
  if (item == null) return null;

  if (item.area.indexOf('CIRCLE') > -1)
  {
    let coordinates = item.area.replace(/CIRCLE|\(|\)|,/g, " ").trim().split(/ +/);

    return { 
      type: 'Feature',
      //geometry: polygon.geometry,
      geometry: {
        type: 'Point',
        coordinates: [Number(coordinates[1]), Number(coordinates[0])]
      },
      properties: {
        name: item.name,
        description: item.description,
        geofenceId: item.id,
        latitude: Number(coordinates[0]),
        longitude: Number(coordinates[1]),
        radius: Number(coordinates[2]),
        icon: (poiCategories.map(poi => poi.name).includes(item.attributes.icon) ? item.attributes.icon : 'generic') || 'generic'
      }
    };
  }
  else
  {
    return { 
      type: 'Feature',
      geometry: reverseCoordinates(wellknown(item.area)),
      properties: {
        name: item.name,
        color: '#' + (item.attributes.color || '3bb2d0'),
        fill: (item.attributes.fill || !item.attributes.hasOwnProperty('fill')) ? 0.1 : 0,
        icon: ''
      }
    };
  }
}


export const getMapIconCategory = (device, position, allowOffline = true) => {
  if (!device || !device.category) return 'sedan_' + getVehicleIconColor(position, allowOffline)
  if (device && device.category.startsWith('3d_static')) {
    return device.category + '_' + getVehicleIconColor(position, allowOffline);
  } else if (device && device.category.startsWith('3d_vehicle')) {
    return device.category + '_' + getVehicleIconColor(position, allowOffline) + "_" + get3dVehicleAngleIndex(position.course);
  }
  return device.category + '_' + getVehicleIconColor(position, allowOffline)
}

const get3dVehicleAngleIndex = (angle) => {
  const icon3dAngleSteps = 30
  var iconAngle = Math.round(angle / icon3dAngleSteps) * icon3dAngleSteps
  if (iconAngle==360) iconAngle = 0;
  return (iconAngle+'').padStart(4, '0')
}

export const getMapIconRotation = (device, position) => {
  if (device && !device.category) return position.course
  if (device && device.category.startsWith('3d_static')) {
    return 0;
  } else if (device && device.category.startsWith('3d_vehicle')) {
    const icon3dAngleSteps = 30
    var iconAngle = Math.round(position.course / icon3dAngleSteps) * icon3dAngleSteps
    var correction = position.course-iconAngle
    if (correction<0) correction = correction+360
    else if (correction>359) correction = correction-360
    return correction;
  }
  return position.course
}

export const getDevice = (deviceId) => {
  var result = global.devices.filter(device => device.id == deviceId)[0]
  if (result === undefined) result = {
    name: '',
    attributes: {
      license_plate: ''
    }
  }
  return result;
}

export const getDeviceIconPath = (category, position) => {
  var subFolder = '2d';
  var angle = "";
  var color = "yellow";

  if (!category) {
    category = 'sedan';
    color = "gray";
  }  

  if (category.startsWith('3d')) {
    subFolder = '3d';
    angle = "_0240";
  }

  if (category.startsWith('3d_static')) {
    subFolder = '3d_static';
    angle = "";
  }

  if (position) color = getVehicleIconColor(position)

  return `images/vehicles/${subFolder}/${category}_${color}${angle}.png`;
}

export const getCategoryDetails = (category) => {
  var result = {name: '', zoom: 1};
  
  deviceCategories2D.concat(deviceCategories3DStatic).concat(deviceCategories3D).forEach(deviceCategory => {
    if (deviceCategory.name == category) result = deviceCategory;
  });

  return result;
}

export const isPrivate = (position) => {
  return position.attributes && position.attributes.privacy && position.attributes.privacy == 1;
}

export const getVehicleIconColor = (position, allowOffline = true) => {
  if (position == false) return "green";
  if ((allowOffline && isOffline(position)) || isPrivate(position)) return "gray";
  if (position && position.attributes) {
    if (position.attributes.ignition && position.speed*1.852>idleSpeed) return 'green'
    if (position.attributes.ignition && position.speed*1.852<=idleSpeed) return 'yellow';
  }
  return "red";
}

export const getVehicleIconTip = (position) => {
  if (isOffline(position)) return t('messageOffline');
  if (isPrivate(position)) return t('private');
  if (position && position.attributes) {
    if (position.attributes.ignition && position.speed*1.852>idleSpeed) return t('messageDriving')
    if (position.attributes.ignition && position.speed*1.852<=idleSpeed) return t('messageIdle');
  }
  return t('messageStopped');
}

export const getIgnitionStatus = (position) => {
  if (isOffline(position) || isPrivate(position)) return 3;
  if (position && position.attributes) {
    if (position.attributes.ignition && position.speed*1.852>idleSpeed) return 0;
    if (position.attributes.ignition && position.speed*1.852<=idleSpeed) return 1;
    if (!position.attributes.ignition) return 2;
  }
  return 3;
}

export const getStatusColor = (status, bright = false) => {
  var ignitionColor = ['#009900', '#e88a25', '#990000', '#707070'];
  if (bright) ignitionColor = ['#00EE00', '#ffcc66', '#DD0000', '#aaaaaa'];
  return ignitionColor[status]
}

export const getIgnitionColor = (position) => {
  return getStatusColor[getIgnitionStatus(position)]
}

export const getImmobilizationColor = (status) => {
  return status ? '#990000' : '#009900';
}

export const isOffline = (position) => {
  if (!position) return true;
  var now = new Date();
  var positionTime   = new Date(position.fixTime);
  return (now.getTime() - positionTime.getTime()) / 1000>24*60*60;
}

export const getOdometerTypeCanBus = (positionAttributes) => {
  return (positionAttributes.hasOwnProperty('io87') && !isNaN(positionAttributes.io87) && positionAttributes.io87>0)
    || (positionAttributes.hasOwnProperty('etd') && !isNaN(positionAttributes.totalDistance) && positionAttributes.totalDistance>0);
}

export const getOdometerValue = (positionAttributes, formatted = true) => {

  var odometer = 0;

  if (positionAttributes.hasOwnProperty('totalDistance') && !isNaN(positionAttributes.totalDistance) && positionAttributes.totalDistance>0) {
    odometer = Math.round(positionAttributes.totalDistance/1000);
  }
  
  if (positionAttributes.hasOwnProperty('td') && !isNaN(positionAttributes.td) && positionAttributes.td>0) {
    odometer = Math.round(positionAttributes.td);
  }
  
  if (positionAttributes.hasOwnProperty('io87') && !isNaN(positionAttributes.io87) && positionAttributes.io87>0) {
    odometer = Math.round(positionAttributes.io87/1000);
  }

  if (positionAttributes.hasOwnProperty('io192') && !isNaN(positionAttributes.io192) && positionAttributes.io192>0) {
    odometer = Math.round(positionAttributes.io192/1000);
  }

  return formatted ? formatBigNumber(odometer) + ' km' : odometer;
}

export const getHourmeterValue = (positionAttributes, formatted = true) => {

  var hourmeter = 0;

  if (positionAttributes.hasOwnProperty('hours') && positionAttributes.hours>0) {
    hourmeter = Math.floor((positionAttributes.hours/1000)/3600);
  }

  return formatted ? formatBigNumber(hourmeter) + ' h' : hourmeter;
}

export const getFuelValue = (position, device, levelDisplay = 0) => {
  var fuel_value = false;

  if (position.attributes.fuel) {
    fuel_value = position.attributes.fuel.toFixed(0);
  }

  if (deviceHasFuelSensor(device)) {
    fuel_value = getAnalogFuelLevel(position, device);
  }

  if (fuel_value) {
    if (levelDisplay == 0) {
      // %
      return fuel_value;
    } else if (levelDisplay == 1) {
      // L / kW
      if (device.attributes.fuel_tank_capacity) {
        return ((fuel_value/100)*device.attributes.fuel_tank_capacity).toFixed(0);
      } else {
        return fuel_value;
      }
    } else if (levelDisplay == 2) {
      // km
      if (position.attributes.range) {
        return (position.attributes.range).toFixed(0);
      } else {
        return '-';
      }
    }
  } else {
    return false;
  }
}

export const getFuelUnit = (device, levelDisplay = 0) => {
    if (levelDisplay == 0) {
      return "%";
    }
    if (levelDisplay == 1) {
      return device.attributes && device.attributes.fuel_type==1 ? "kW" : "L";
    }
    if (levelDisplay == 2) {
      return "km";
    }
    return "";
}

export const getTemperatureValue = (positionAttributes, device = false, deviceSettings = false) => {

  if (positionAttributes.hasOwnProperty('temp1')) {
    return positionAttributes.temp1.toFixed(1) + ' °C';
  }

  return false;
}

export const getFuelColor = (position, device) => {
  var fuel_level = getFuelValue(position, device, 0);

  if (fuel_level) {
    if (fuel_level>50) return '#009900';
    if (fuel_level>25) return '#e88a25';
    return '#990000';    
  }

  return '#999999';
}

export const deviceHasFuelSensor = (device) => {
	return (device.attributes.hasOwnProperty('fuelsensor_port') && device.attributes.fuelsensor_port>0
    && device.attributes.hasOwnProperty('fuel_tank_capacity') && device.attributes.fuel_tank_capacity>0
    && device.attributes.hasOwnProperty('fuel_low_threshold') && device.attributes.hasOwnProperty('fuel_high_threshold')
    && (device.attributes.fuel_low_threshold>0 || device.attributes.fuel_high_threshold>0)
    && device.attributes.fuel_low_threshold != device.attributes.fuel_high_threshold
    );
}

export const getAnalogFuelLevel = (position, device) => {
  if (position.attributes.hasOwnProperty('adc1') && position.attributes.adc1>0) {
    var fuel_sensor = (device.attributes.fuel_low_threshold-position.attributes.adc1)/(device.attributes.fuel_low_threshold-device.attributes.fuel_high_threshold);
    if (fuel_sensor>1.1) return false;
    if (fuel_sensor>1) fuel_sensor = 1;
    if (fuel_sensor<0) fuel_sensor = 0;        
    return (fuel_sensor * 100).toFixed(0);
    
  }
  return 0;
}

export const getBatteryValue = (positionAttributes, deviceAttributes = false) => {

  if (typeof(positionAttributes.power) == 'undefined') return '';

  var deviceType = (deviceAttributes && deviceAttributes.hasOwnProperty('device_type') ? deviceAttributes.device_type : 1);

  const batteryDeviceTypes = [2, 15];

  return positionAttributes.power.toFixed(1) + (batteryDeviceTypes.includes(deviceType) || positionAttributes.battery ? ' %' : ' V');
}

export const getDeviceType = (device) => {
  return device.attributes && device.attributes.hasOwnProperty('device_type') ? device.attributes.device_type : 1;
}

export const getBatteryColor = (positionAttributes, deviceAttributes = false) => {

  if (typeof(positionAttributes.power) == 'undefined') return '#999999';

  var deviceType = (deviceAttributes && deviceAttributes.hasOwnProperty('device_type') ? deviceAttributes.device_type : 1);

  const batteryDeviceTypes = [2, 15];

  if ( batteryDeviceTypes.includes(deviceType) ) {
    if (positionAttributes.power>50) return '#009900';
    if (positionAttributes.power>25) return '#e88a25';
    return '#990000';
  } else {
    if (positionAttributes.power>=12) return '#009900';
    if (positionAttributes.power>=11) return '#e88a25';
    return '#990000';
  }
}


export const filterDevice = (device, drivers, position = false, searchText = '', groupId = 0, status = '') => {
  var show = false

  var device2 = Object.assign({}, device);;
  if (position) device2.position = position;

  if (searchText == '') {
    show = true
  } else {
    show = getDeviceName(device2).toLowerCase().indexOf(searchText ? searchText.toLowerCase() : "") != -1
            || (
              drivers[device2.attributes.driverId]
              && (drivers[device2.attributes.driverId].name.toLowerCase().indexOf(searchText ? searchText.toLowerCase() : "") != -1 )
            )
            || (
              device2.driver
              && device2.driver.name
              && (device2.driver.name.toLowerCase().indexOf(searchText ? searchText.toLowerCase() : "") != -1 )
            )
  }

  if (show && groupId > 0) {
    show = device2.groupId == groupId
  }

  switch (status) {
    case 'driving':
      show = show && isDriving(device2, position);
      break;

    case 'idle':
      show = show && isIdle(device2, position);
      break;

    case 'stop':
      show = show && isStop(device2, position);
      break;

    case 'warning':
      show = show && hasWarning(device2, position);
      break;

    case 'maintenance':
      show = show && inMaintenance(device2);
      break;
  
    default:
      break;
  }

  if (isPrivate(position)) show = false;

  return show;
};


export const isDriving = (device, position = false) => {
  if (position) device.position = position;
  return device.position.attributes.ignition && device.position.speed*1.852>idleSpeed;
}

export const isIdle = (device, position = false) => {
  if (position) device.position = position;
  return device.position.attributes.ignition && device.position.speed*1.852<=idleSpeed;
}

export const isStop = (device, position = false) => {
  if (position) device.position = position;
  return !device.position.attributes.ignition;
}

export const hasWarning = (device, position = false) => {
  if (position) device.position = position;
  return getBatteryColor(device.position.attributes, device.attributes) == '#990000'
  || (
    !device.position.valid
    &&device.position.attributes.hasOwnProperty('event')
    && device.position.event!=150 && device.position.event!=151 && device.position.event!=152 //CAN-Bus messages has valid=0
    && !(device.position.attributes.io200 > 0)
  )
  || isOffline(device.position)
  || ( device.position.attributes.hasOwnProperty('rssi') && device.position.attributes.rssi<=1 );
}

export const inMaintenance = (device) => {
  return device.attributes.in_maintenance;
}

export const getTimeValue = (fixTime, lastignition, ignition) => {

  if (formatTimeAgo(lastignition*1000) != 'Invalid date' && typeof(lastignition) == 'number') {
    return (ignition ? t('mapDrivingFor') : t('mapParkedFor')) + ' ' + formatTimeAgo(lastignition*1000)
  }

  if (formatDate(fixTime) != 'Invalid date') {
    return formatDate(fixTime)
  }

  return '';

}

export const getDeviceStatus = (position) => {
  const timeAgo = formatTimeAgo(position.attributes.lastignition*1000, position.attributes.ignition) 
  if (timeAgo == 'Invalid date' || typeof(position.attributes.lastignition) != 'number' || (typeof(position.attributes.lastignition) == 'number' && position.attributes.lastignition < 10000000)) {
    if (formatDate(position.fixTime) != 'Invalid date') {
      return formatDate(position.fixTime);
    } else {
      return ''
    }
  } else {
    if (position.attributes.ignition) {
      if (position.speed * 1.852 > idleSpeed) {
        return t('mapDrivingFor') + ' ' + timeAgo;
      } else {
        var idleTime = '';
        if (position
          && position.attributes
          && position.attributes.lastignition
          && position.attributes.lastdriving
          && position.attributes.lastdriving>=position.attributes.lastignition) {
            idleTime = formatTimeAgo(position.attributes.lastdriving*1000) + " "
          }
        return t('mapDrivingFor') + ' ' + timeAgo + " (" + idleTime + t('mapIdling').toLowerCase() + ")";
      }
    } else {
      return t('mapParkedFor') + ' ' + timeAgo;
    }
  }
}

export const distance = (lat1, lon1, lat2, lon2) => {
  const earth_radius = 6373000; //approximate radius of earth in km

  lat1 = lat1 * (Math.PI/180);
  lon1 = lon1 * (Math.PI/180);
  lat2 = lat2 * (Math.PI/180);
  lon2 = lon2 * (Math.PI/180);

  const dlon = lon2 - lon1;
  const dlat = lat2 - lat1;

  const a = Math.sin(dlat / 2) ** 2 + Math.cos(lat1) * Math.cos(lat2) * Math.sin(dlon / 2) ** 2;
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return earth_radius * c;
}

export const getClosestGeofence = (geofences, latitude, longitude, includePolygon = true, includeDistance = false) => {
  var geofenceType = false
  var geofenceName = false
  var closestDistance = -1;
  var shortestArea = -1;

  geofences
    .filter(geofence => (
      geofence.area &&
        (
          geofence.area.toLowerCase().startsWith("circle")
          || geofence.area.toLowerCase().startsWith("polygon")
        )))
    .forEach(function(geofence) {
    geofence = geofenceToFeature(geofence)

    if (geofence.geometry && geofence.geometry.type == 'Point') {
      var pointDistance = distance(latitude, longitude, geofence.geometry.coordinates[1], geofence.geometry.coordinates[0]);
      if (pointDistance < geofence.properties.radius
        && (closestDistance<0 || pointDistance < closestDistance)) {
          geofenceType = "point"
          geofenceName = geofence.properties.name
          closestDistance = pointDistance
          shortestArea = -1
      }
    }

    if (geofence.geometry && geofence.geometry.type == 'Polygon' && includePolygon && closestDistance<0) {
      if (turf.booleanPointInPolygon(turf.point([longitude, latitude]), geofence)) {
        const PolygonArea = turf.area(geofence);
        if (shortestArea<0 || PolygonArea < shortestArea) {
          geofenceType = "polygon"
          geofenceName = geofence.properties.name
          closestDistance = -1
          shortestArea = PolygonArea
        }
      }
    }
  })

  if (geofenceName && closestDistance>0 && includeDistance) geofenceName += ` (${Math.round(closestDistance)} m)`

  return { name: geofenceName, type: geofenceType }
}

export const getDeviceName = (device, showGroup = false, groups = []) => {

  if (!device) return "";

  if (device.hasOwnProperty('attributes')) {
    if (device.attributes.hasOwnProperty('license_plate')) {
      var deviceName = '';
      var deviceGroup = groups[device.groupId] && groups[device.groupId].name ? groups[device.groupId].name : '';
      if (showGroup) deviceName += deviceGroup != '' ? `[${deviceGroup}] ` : '[Sem grupo] '
      deviceName += device.name;
      if (!device.name.includes(device.attributes.license_plate) && device.attributes.license_plate !== null) deviceName +=  ` (${device.attributes.license_plate})`;
      return deviceName;
    } else if (device.hasOwnProperty('uniqueId')) {
      var deviceGroup = device.hasOwnProperty('group') ? device['group'] : '';
      return (showGroup ? (deviceGroup != '' ? `[${deviceGroup}] ` : '[Sem grupo] ') : '') + device.name;
    }
  }
  if (device.hasOwnProperty('email')) {
    return `${device.name} (${device.email})`;
  }

  return device.name;
};

export const immobilizationState = (device, position = false) => {
  
  if (!position) {
    position = device.position
  }

  if (device.attributes.has_immobilization) {
    if (position.attributes.hasOwnProperty('result')) {
      if (position.attributes.result.includes('DOUT1:1')) return true;
      if (position.attributes.result.includes('DOUT1:0')) return false;
    }
    if (position.attributes.out1) return true;
    if (!position.attributes.out1) return false;
  }
  return null
}

export const getDriverFromUniqueId = (drivers, uniqueId) => {
  if (uniqueId == '0' || uniqueId == null || uniqueId == '') return false;
  var result = false;
  if (uniqueId) {
    for (var i = 0; i < drivers.length; i++) {
      if (drivers[i].uniqueId == uniqueId) {
        result = drivers[i]
        break;
      }      
    }   
  }
  if (!result && uniqueId && uniqueId.startsWith('x.')) return false;
  if (!result) return {name: 'RFID: ' + uniqueId, uniqueId }
  return result;  
}

export const getTagNames = (position) => {

  const tags = global.tags;

  if (Object.keys(tags).length>0 && position.attributes && position.attributes.tags && position.attributes.tags != '') {
    return Object.values(tags)
      .filter(
        tag => position.attributes.tags
        .replace(';', '')
        .toLowerCase()
        .includes(
          tag.tag
          .replace(';', '')
          .toLowerCase()))
      .map(tag => tag.name)
  } else {
    return []
  }
}

export const renderPopupGeofence = (geofence) => {

  return renderToString(
    <div style={{ padding: '5px 20px 5px 10px', fontFamily: 'Proxima Nova Regular' }}>

      <div style={{display: 'flex', marginBottom: '5px'}}>
        <div style={{fontWeight: 'bold', fontSize: '1.2rem'}}>
          { geofence.name }
        </div>
      </div>

      <div style={{fontWeight: 'bold', fontSize: '0.8rem', marginBottom: '5px'}}>
        { geofence.description }
      </div>

      <div style={{display: 'flex', marginBottom: '5px'}}>
        <div style={{textAlign: 'center', minWidth: '15px'}}>
          <FontAwesomeIcon icon={faGlobe}  style={{color: '#78A9FF'}}/>
        </div>
        <div style={{float: 'right', paddingLeft: '5px'}}>
          { geofence.latitude.toFixed(6) + ', ' + geofence.longitude.toFixed(6) }
          <a class="tooltip" href={'https://www.google.com/maps/place/' + geofence.latitude + ',' + geofence.longitude + '/@' + geofence.latitude + ',' + geofence.longitude + ',12z'} target='_blank' style={{color: 'black', textDecoration: 'none', marginLeft: '5px', position: 'relative', top: '2px', fontSize: '1rem'}}>
            <FontAwesomeIcon icon={faLocationArrow}  style={{color: '#78A9FF'}}/>
            <span class="tooltiptext" style={{ marginLeft: '0px' }}>
              Navegar
            </span>
          </a>
        </div>
      </div>
      
    </div>
  )
}

export const minutesToHM = (minutes) => {
  if (isNaN(minutes)) return "";
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  return `${hours.toString().padStart(2, '0')}:${remainingMinutes.toString().padStart(2, '0')}`;
}

export const timestampToDate = (timestamp) => {
  const date = new Date(timestamp*1000);

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');

  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}
